import React from "react";

const ProductCard = ({ id, image, pname, price }) => {
  return (
    <>
      <div
        className="p-0 m-0 pro-img"
        style={{
          marginLeft: "30px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src={image}
          className="img-fluid rounded-circle"
          alt="img"
          style={{ width: "150px", height: "150px", border: "5px solid pink" }}
        />
          <div className="">
          <h6 className="pro-info top-padding">{pname}</h6>
        </div>
      </div>
    </>
  );
};

export default ProductCard;
