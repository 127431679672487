import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import jsondata from "..//product.json";
import welcome from "../components/welcome.webp";
import { Link } from "react-router-dom";
export const ProductAll = () => {
  const { id } = useParams();
  const [category, setCategory] = useState(null);
  const [ourproduct, setOurproduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // State to track errors

  useEffect(() => {
    const fetchData = async () => {
      try {
        const selectedCategory = jsondata.data[0].category[id];
        setCategory(selectedCategory);
        setOurproduct(jsondata.data[0].category);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Error fetching data. Please try again later.");
        setLoading(false);
      }
    };

    fetchData();

    // Cleanup function
    return () => {
      // Any cleanup code if needed
    };
  }, [id]);
  const handleRedirect = (path) => {
    window.location.href = path; // Redirect to the specified path
  };

  return (
    <div className="container">
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : category ? (
        <div className="row">
          <div className="col-md-9">
            <div className="main-category mt-4">
              <h2>
                {" "}
                <img src={welcome} alt="" />
                {category.name} <span className="text-danger">Product</span>
              </h2>
              <br />
              <p className="m-4 text-muted " style={{ fontWeight: "500" }}>
                {category.description}
              </p>
            </div>
            <div className="product-items mt-3">
              <p
                className="bg-success  text-light text-center"
                style={{
                  margin: "10px 0",
                  fontWeight: "500",
                  fontSize: "22px",
                  padding: "10px 15px",
                }}
              >
                {" "}
                List of Product
              </p>
              <div className="row">
                {category.products
                  .reduce((acc, product, index) => {
                    const groupIndex = Math.floor(index / 10);
                    if (!acc[groupIndex]) {
                      acc[groupIndex] = [];
                    }
                    acc[groupIndex].push(
                      <li
                        key={index}
                        className="p-2 text-light my-3 fs-6 "
                        style={{ backgroundColor: "orange" }}
                      >
                        {product.pname}
                      </li>
                    );
                    return acc;
                  }, [])
                  .map((group, groupIndex) => (
                    <div className="col-sm-4" key={groupIndex}>
                      <ul className="">{group}</ul>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="col-md-3 mt-5">
            <div className="our-product d-flex justify-content-center align-items-center">
              <div className="list-cate ">
                <h5
                  className="text-light fw-bold "
                  style={{
                    padding: "15px 80px",
                    boxShadow: "0px 0px 5px black",
                  }}
                >
                  Our Product
                </h5>
                {ourproduct.map((items, index) => (
                  <ul key={index}>
                    <li className="list-cate">
                      {" "}
                      <i className="fa fa-check"></i>
                      <Link
                        className="list-cate best-link"
                        to="#"
                        onClick={() => handleRedirect(`/category-product/${items.id}`)}
                      >
                        {items.name}
                      </Link>
                    </li>
                  </ul>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p>No category found</p>
      )}
    </div>
  );
};

export default ProductAll;
