import React from "react";
import Department from '../components/Department'
export default function About() {
   //  const containerStyle = {
   //      backgroundImage:
       
   //        "url(assets/image/welcome-hme-1-bg.jpg)",
   //       //  assets/image/welcome-hme-1-bg.jpg
   //    };
   
    return (
        <>
        {/* <section className="welcome_section hme-one"  id="about-section" style={containerStyle}>  */}
        <section className="welcome_section hme-one bg-white"  id="about-section">
        <div className="container">    
           <div className="row">
              <div className="col-lg-6">
                 <div className="welcme_inner">
                    <div className="heading clearfix">
                          <h1>WELCOME TO <span>Arisechemical </span></h1>
                    </div>
                    <h6>Our mission is to provide first-class independent healthcare for the local community in a safe, comfortable, and welcoming environment.</h6>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s.</p>
                    <ul>
                       <li><img src="assets/image/awards-1.png" className="img-fluid" alt="awards" /></li>
                       <li><img src="assets/image/awards-2.png" className="img-fluid" alt="awards" /></li>
                       <li><img src="assets/image/awards-3.png" className="img-fluid" alt="awards" /></li>
                       <li><img src="assets/image/awards-4.png" className="img-fluid" alt="awards" /></li>
                    </ul>
                    <a href="#" className="theme-btn adjust-btn">GET IN TOUCH</a>
                 </div>
           </div>
              <div className="col-lg-6">
                   <img src="https://hkangles.com/kanabis/demo1/wp-content/uploads/sites/5/2024/02/bottle-01-1.png" alt="" />
              </div>
           </div>
        </div>
     </section>
     
    
     
        </>
    )
}