import React from "react";
import Navbar from "./components/Navbar";
import MainSlider from "./components/MainSlider";
import About from "./components/About";
import Department from "./components/Department";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import TopProduct from "./components/TopProduct";
import { OurCategory } from "./components/OurCategory";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import { ProductAll } from "./components/ProductAll";
import Product from "./components/Product";
import MoreProducts from "./components/MoreProducts";
import { Allcategory } from "./components/Allcategory";


function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <MainSlider />
                <About />
                <TopProduct />
                <OurCategory />
                <Contact />
              </>
            }
          />
          <Route path="/about" element={<About />} />
          <Route path="/contact-section" element={<Contact />} />
          <Route path="/Our-category" element={<OurCategory />} />
          <Route path="/product-list" element={<Allcategory />} />
          <Route path="/category-product/:id" element={<ProductAll />} />
          <Route path="/top-product" element={<TopProduct />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
