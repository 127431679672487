import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import jsondata from "../product.json";
import logo from "../arise_chemical_log.png";
import '../App.css';

export default function Navbar() {
  const [categoriesdata, setCategories] = useState([]);

  const handleRedirect = (path, e) => {
    e.preventDefault();
    window.location.href = path; // Redirect to the specified path
  };

  useEffect(() => {
    if (jsondata) {
      const initialCategories = jsondata.data[0].category;
      console.log(`intifial ${initialCategories}`);
      setCategories(initialCategories);
    }
  }, []);
  console.log(`Ctegory ${categoriesdata}`);
  return (
    <>
      <header className="site-header" id="header-one">
        <div className="top-bar">
          <div className="container">
            <div className="row clearfix">
              <div className="col-lg-12">
                <div className="float-left">
                  <p>
                    <i className="fa fa-map-marker"></i> 208,209, Sheeba
                    Complex, MG Road, Indore
                  </p>
                </div>
                <div className="float-right ">
                  <div className="inner-sec">
                    <ul className="social-media">
                      <li>
                        <a href="#">
                          <i className="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa fa-pinterest"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa fa-vimeo"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa fa-share-alt"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="navbar navbar-expand-lg bsnav bsnav-sticky bsnav-sticky-slide" style={{background: "linear-gradient(45deg, #1a3e4e, #325261)"}}>
          <div className="container ">
            <Link
              className="navbar-brand text-dark fw-bold"
              to="/"
              onClick={(e) => handleRedirect("/", e)}
            >
              <img src={logo} class="logoMaindata"/>
            </Link>
            <button className="navbar-toggler toggler-spring">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse">
              <ul className="navbar-nav ml-auto mr-auto">
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link link_hd adjust_link"
                    to="#"
                    onClick={(e) => handleRedirect("/", e)}
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item dropdown ">
                  <Link
                    className="nav-link link_hd adjust_link"
                    to="/about"
                    onClick={(e) => handleRedirect("/about", e)}
                  >
                    About Us
                  </Link>
                </li>
                <li className={`nav-item dropdown`}>
                  <Link
                    className="nav-link link_hd"
                    to="/product-list"
                    onClick={(e) => handleRedirect("/product-list", e)}
                  >
                    Products
                  </Link>
                  <ul
                    className={`navbar-nav navbar-mobile submenu adujst-submenu ${categoriesdata.length}`}
                  >
                    {categoriesdata.map((item, index) => (
                      <li className="nav-item activee" key={`${index}${item.name}`}>
                        <Link
                          className="nav-link"
                          to={`/category-product/${item.id}`}
                          onClick={(e) =>
                            handleRedirect(`/category-product/${item.id}`, e)
                          }
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link link_hd adjust_link"
                    to="/contact-section"
                    onClick={(e) => handleRedirect("/contact-section", e)}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      <div className="bsnav-mobile">
        <div className="bsnav-mobile-overlay"></div>
        <div className="navbar">
          <button className="navbar-toggler toggler-spring mobile-toggler">
            <span className="fa fa-close"></span>
          </button>
          <ul class="navbar-nav navbar-mobile ml-auto mr-auto">
            <li class="nav-item activee">
              <a class="nav-link link_hd adjust_link" href="/">
                Home
              </a>
            </li>
            <li class="nav-item activee">
              <a class="nav-link link_hd adjust_link" href="/about-section">
                About Us
              </a>
            </li>
            <li class="nav-item activee">
              <a class="nav-link link_hd" href="/product-list">
                Products
              </a>
              <ul class="navbar-nav submenu adujst-submenu">
                {categoriesdata.map((item, index) => (
                  <li class="nav-item activee" key={`menu${index}`}>
                    <a class="nav-link" href={`/category-product/${item.id}`}>
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
            <li class="nav-item activee">
              <a class="nav-link link_hd adjust_link" href="/contact-section">
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
