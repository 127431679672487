import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import jsondata from "..//product.json";


export default function Footer() {
  const [categories, setCategories] = useState([]);
  
  useEffect(() => {
    if (jsondata) {
      const initialCategories = jsondata.data[0].category;
      setCategories(initialCategories);
    }
  }, []);

  const halfIndex = Math.ceil(categories.length/2);
  const firstHalfCategories = categories.slice(0, 6);
  const secondHalfCategories = categories.slice(6);
  const handleRedirect = (path) => {
    window.location.href = path; // Redirect to the specified path
  };

  return (
    <>
      {/* <!-------contact-through-----> */}
      <div className="contact_through">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-3 col-sm-6">
              <div className="contact_through_inner">
                <span className="flaticon-phone-1 icon"></span>
                <p>
                  Emergency Cases: <br />
                  (+91) 982-603-4990
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-3 col-sm-6">
              <div className="contact_through_inner">
                <span className="flaticon-placeholder icon"></span>
                <p>
                  {" "}
                  208,209, Sheeba Complex,
                  <br /> MG Road, Indore
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-3 col-sm-6">
              <div className="contact_through_inner">
                <span className="flaticon-envelope icon"></span>
                <p>
                  Email Address
                  <br />
                  Arisechemical@gmail.com
                </p>
              </div>
            </div>
           
          </div>
        </div>
      </div>
      {/* <!-------contact-through-----> */}

      {/* <!--------footer--------> */}
      <div className="footer home-one">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="footer-widget first">
                <h2>ABOUT US</h2>
                <div className="footer-text">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries.
                  </p>

                  <ul className="social-media">
                    <li>
                      <a href="#" target="_blank">
                        <i className="fa fa-facebook-f"></i>
                      </a>{" "}
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="fa fa-twitter"></i>
                      </a>{" "}
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="fa fa-vimeo"></i>
                      </a>{" "}
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="fa fa-wifi"></i>
                      </a>{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-2 ">
              <div className="footer-widget two">
                <h2>Quick Links</h2>
                <div className="footer-text">
                  <ul>
                    <li>
                      <Link to="#"  onClick={() => handleRedirect('/')} >Home</Link>
                    </li>
                    <li>
                      <Link to="#"  onClick={() => handleRedirect('/about')} >About Us</Link>
                    </li>
                    <li>
                      <Link  to="#"  onClick={() => handleRedirect('/product-list')}  >Products</Link>
                    </li>
                    <li>
                      <Link to="#"  onClick={() => handleRedirect('/contact-section')}>Contacts</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footer-widget two">
                <h2>Product Category</h2>
                <div className="footer-text">
                  <ul>
                    {firstHalfCategories.map((items, index) => (
                      
                      <li className="nav-item" key={index}>
                        <Link
                          className="nav-link"
                          to="#"
                          onClick={() => handleRedirect(`/category-product/${items.id}`)}
                        >
                          {items.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footer-widget two  ">
                <div className="footer-text">
                  <ul>
                    {secondHalfCategories.map((items, index) => (
                      <li className="nav-item" key={index}>
                        <Link
                          className="nav-link "
                          to="#"
                          onClick={() => handleRedirect(`/category-product/${items.id}`)}
                        >
                          {items.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-last home-one">
        <div className="container">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="left">
                <p>
                  © 2024 <span>Arisechemical</span> All right are reserved.
                </p>
              </div>
              <div className="right">
                <ul>
                  <li>
                    <a href="#">Privacy Policy </a>
                  </li>
                  <li>
                    <a href="#"> Terms & Conditions </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
