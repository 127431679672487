
import React, { useEffect, useState } from "react";
import jsondata from "..//product.json";
import { Link } from "react-router-dom";

export const OurCategory = () => {
  const [categories, setCategories] = useState([]);
  
  const handleRedirect = (path) => {
    window.location.href = path; // Redirect to the specified path
  };

  useEffect(() => {
    if (jsondata) {
      const initialCategories = jsondata.data[0].category.slice(0, 6);
      setCategories(initialCategories);
    }
  }, []);

  return (
    <><section className="bg-white" id="#category-section">

      <div
        className="blog-grid-section ui-group pt-0"
        id="category-section"
        data-js="filter-sec"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="heading clearfix">
                <h1>
                  Our <span>Category</span>
                </h1>
              </div>
            </div>
          </div>
          <div className="row">
            {categories.map((category, index) => (
              <div
                className="grid-item col-md-4 col-xs-6 col-sm-12 blog-grid-outer Business px-3 py-1 "
                key={index}
              >
                <Link  to="#"
                          onClick={() => handleRedirect(`/category-product/${category.id}`)}>
                  <div
                    className="blog-grid-inner best shadow "
                    style={{ border: "1px solid pink" }}
                  >
                    <div className="image">
                      <img
                        src={category.image}
                        className="img-fluid"
                        alt="case"
                      style={{width:"400px", height:"200px"}}
                      />
                      <a href="#" className="link"></a>
                    </div>
                    <div className="blog-grid-content text-center  ">
                      <span className="logic-icon">
                        {" "}
                        <i className="fa fa-align-left "></i>
                      </span>
                      <h2 className=" text-center handle">
                          {category.name}
                      </h2>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>

        
            <div className="row m-0 p-0">
              <div className="col-md-12 text-center pagination_column p-0 m-0">
                <Link className="theme-btn adjust-btn" to="#"
                    onClick={() => handleRedirect('/product-list')}>
                  More Categories
                </Link>
              </div>
            </div>
        </div>
      </div>
    </section>
    </>
  );
};
