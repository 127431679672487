import React, { useEffect } from "react";

export default function Contact() {
  const textStyle = {
    color: "#568701",
    fontWeight: 800,
  };

  return (
    <>
      <div className="st-google-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96652.27317354927!2d-74.33557928194516!3d40.79756494697628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3a82f1352d0dd%3A0x81d4f72c4435aab5!2sTroy+Meadows+Wetlands!5e0!3m2!1sen!2sbd!4v1563075599994!5m2!1sen!2sbd"
          width="100%"
          height="500"
        ></iframe>
      </div>
      <div className="contact_address pt-5 bg-white" id="contact-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="heading fontWeight">
                <h1 className="fw-normal">
                  Contact <span style={textStyle}>Us</span>
                </h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="contact_address_inner">
                <div className="contact_our_office">
                  <h2>
                    Our <span>Office</span>
                  </h2>
                  <p>7088 Micaela Cliffs, Thielshire, OK 95062</p>
                </div>
                <div className="contact_get_in_touch">
                  <h2>
                    Get in <span>Touch</span>
                  </h2>
                  <div className="mail">
                    <p>
                      EMAIL
                      <br />
                      <a href="#">Arisechemical@gmail.com</a>
                    </p>
                  </div>
                  <div className="phone">
                    <p>
                      Emergency Cases: <br />
                      (+91) 982-603-4990
                    </p>
                  </div>
                </div>
                <div className="contact_social_media">
                  <ul className="social-media">
                    <li>
                      <a href="#" target="_blank">
                        <i className="fa fa-facebook-f"></i>
                      </a>{" "}
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="fa fa-twitter"></i>
                      </a>{" "}
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="fa fa-vimeo"></i>
                      </a>{" "}
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="fa fa-wifi"></i>
                      </a>{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-12">
              <div className="contact_pg_form">
                <form
                  id="contact-form"
                  method="post"
                  action="https://st.ourhtmldemo.com/template/meditex-final/contact.php"
                  role="form"
                >
                  <div className="messages"></div>

                  <div className="controls">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            id="form_name"
                            type="text"
                            name="name"
                            placeholder="Name *"
                            required="required"
                            data-error="NameFirstname is required." 
                            style={{ border: "1px solid pink" }}
                          />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            id="form_email"
                            type="email"
                            name="email"
                            placeholder=" Email *"
                            required="required"
                            data-error=" Email is required."
                            style={{ border: "1px solid pink" }}
                          />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            id="form_phone"
                            type="text"
                            name="phone"
                            placeholder="Phone *"
                            required="required"
                            data-error="Phone is required."
                            style={{ border: "1px solid pink" }}
                          />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <textarea
                            id="form_message"
                            name="message"
                            placeholder="Message "
                            rows="6"
                            required="required"
                            data-error="Please, leave us a message."
                            style={{ border: "1px solid pink" }}
                          ></textarea>
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <input
                          type="submit"
                          className="theme-btn btn-send adjust-btn"
                          value="Send message"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
