import React, { useState, useEffect } from "react";
import ProductCard from "./ProductCard";
import jsonData from "../product.json"; // Adjust the path accordingly
import { OurCategory } from "./OurCategory";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from "react-router-dom";

export default function TopProduct() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    try {
      const category = jsonData.data[0].category;
      // Check if category exists and is an array
      if (Array.isArray(category)) {
        let productList = [];
        for (const cat of category) {
          for (const product of cat.products) {
            if (product.featured === "1") {
              productList.push({
                pname: product.pname,
                image: product.image,
                featured: product.featured
              });
            }
          }
        }
        setProducts(productList);
      } else {
        console.error("Category is not an array.");
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }, []);

  const settings = {
  
    infinite: true,
    speed: 500,
    slidesToShow: 6, // Display six items
    slidesToScroll: 1,
    autoplay: true, // Enable automatic scrolling
    autoplaySpeed: 2000, // Set the speed of automatic scrolling in milliseconds
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        
        }
      }
    ]
  };

  return (
    <>
      <section className="product_details_outer pt-3 pb-3 p-0 m-0 bg-white" id="product-section">
        <div className="container">
          <div className="row products_caro">
            <div className="col-lg-12">
              <div className="heading">
                <h1 className="top-product">Top <span>Products</span></h1>
              </div>
            </div>
            <div className="col-lg-12 text-center">
              <Slider {...settings}>
                {products.map((product, index) => (
                  <div key={index}>
                    <ProductCard
                      pname={product.pname}
                      image={product.image}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
