import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import About from "./About";

export default function MainSlider() {
  // const slides = [
  //   {
  //     imageUrl: "assets/image/banner/home-1-banner-1.jpg",
  //     titleSecond: "THE CARE THEY DESERVE",
  //     text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //   },
  //   {
  //     imageUrl: "assets/image/banner/home-1-banner-2.jpg",
  //     title: "Advanced Medicine,",
  //     titleSecond: "Trusted Care.",
  //     text: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  //   },
  //   {
  //     imageUrl: "assets/image/banner/home-1-banner-3.jpg",
  //     title: "Enhancing Life,",
  //     titleSecond: "Excelling in Care.",
  //     text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  //   },
  // ];
  const slides = [
    {
      imageUrl: "assets/image/banner/home-1-banner-1.jpg",
      titleSecond: "THE CARE THEY DESERVE",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      imageUrl: "assets/image/banner/home-1-banner-2.jpg",
      title: "Advanced Medicine,",
      titleSecond: "Trusted Care.",
      text: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      imageUrl: "assets/image/banner/home-1-banner-3.jpg",
      title: "Enhancing Life,",
      titleSecond: "Excelling in Care.",
      text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
  ];
  const options = {
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout: 6000,
    nav: false,
    dots: false,
    margin: 0,
    responsive: {
      1100: {
        items: 1,
      },
      724: {
        items: 1,
      },
      500: {
        items: 1,
      },
      370: {
        items: 1,
        innerWidth: "100%",
        outerWidth: "100%",
      },
    },
  };

  return (
    <>
      <section className="main-slider" id="home-section">
        <div className="main-slider-carousel main-slider-two">
          <OwlCarousel className="owl-theme" {...options}>
            {slides.map((slide, index) => (
              <div className="item" key={index}>
                <div
                  className="slide"
                  style={{ backgroundImage: `url(${slide.imageUrl})` }}
                >
                  <div className="container text-left">
                    <div className="content text-adjust">
                      <h1 className="text-light">
                        {slide.title}
                        <br />
                        <span>{slide.titleSecond}</span>
                      </h1>
                      <div className="text text-muted">{slide.text}</div>
                      <div className="link-box mt-4">
                        <a href="#" className="theme-btn adjust-btn">
                          DEPARTMENTS
                        </a>
                        <a
                          href="#"
                          className="theme-btn btn-dark adjust-btn-dark"
                        >
                          GET IN TOUCH
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        </div>
      </section>
    </>
  );
}
