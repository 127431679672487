import React, { useEffect, useState } from "react";
import jsondata from "..//product.json";
import { Link } from "react-router-dom";

export const Allcategory = () => {
  const [categories, setCategories] = useState([]);
  const [showAll, setShowAll] = useState(false);
//   useEffect(() => {
//     const handleLinkClick = (event) => {
//         // Prevent the default behavior of the link click
//         event.preventDefault();

//         // Scroll to the top of the page
//         window.scrollTo({
//             top: 0,
//             behavior: "smooth"
//         });

//         // Extract the target URL from the clicked link and navigate manually
//         const targetUrl = event.currentTarget.getAttribute("to");
//         if (targetUrl) {
//             // Navigate to the target URL
//             window.location.href = targetUrl;
//         }
//     };

//     // Add event listeners to all links with a 'to' attribute starting with "/"
//     document.querySelectorAll('a[to^="/"]').forEach((Link) => {
//         Link.addEventListener("click", handleLinkClick);
//     });

//     // Remove event listeners when the component unmounts
//     return () => {
//         document.querySelectorAll('a[to^="/"]').forEach((Link) => {
//             Link.removeEventListener("click", handleLinkClick);
//         });
//     };
// }, []);  
const handleRedirect = (path) => {
  window.location.href = path; // Redirect to the specified path
};

  useEffect(() => {
    if (jsondata) {
      const initialCategories = jsondata.data[0].category;
      setCategories(initialCategories);
    }
  }, []);
  return (
    <div
      className="blog-grid-section ui-group pt-0 bg-white"
      id="category-section"
      data-js="filter-sec"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="heading clearfix">
              <h1>
                Our <span>Category</span>
              </h1>
            </div>
          </div>
        </div>
        <div className="row">
          {categories.map((category, index) => (
            <div
              className="grid-item col-md-4 col-xs-6 blog-grid-outer Business px-3 py-1 "
              key={index}
            >
              <Link  to="#"
                          onClick={() => handleRedirect(`/category-product/${category.id}`)}>
                <div
                  className="blog-grid-inner best shadow "
                  style={{ border: "1px solid pink" }}
                >
                  <div className="image">
                    <img
                      src={category.image}
                      className="img-fluid"
                      alt="case"
                      style={{ width: "400px", height: "200px" }}
                    />
                    <a href="#" className="link"></a>
                  </div>
                  <div className="blog-grid-content text-center  ">
                    <span className="logic-icon">
                      {" "}
                      <i className="fa fa-align-left "></i>
                    </span>
                    <h2 className=" text-center handle">
                      <Link
                        className="fs-5"
                        to="#"
                          onClick={() => handleRedirect(`/category-product/${category.id}`)}
                      >
                        {category.name}
                      </Link>
                    </h2>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
